import {brandingStore} from '@/store/__STORE_branding';

export default {
  methods: {
    pollData(fast) {
      const frequency = (fast ? 10000 : 30000);
      this.polling = setInterval(() => {
        this.getData();
      }, frequency);
    },
    startLiveTimer() {
      this.liveTimer = setInterval(() => {
        this.seconds++;
      }, 1000);
    },
    stopLiveTimer() {
      clearInterval(this.liveTimer);
      this.liveTimer = null;
      this.seconds = 0;
    },
    liveTime(time) {
      return time + this.seconds;
    },
    chartContainerWidth() {
      return this.$refs.chartContainer.clientWidth;
    },
    getLabels(array) {
      if (array && array.length > 0) {
        return array.map((prop) => prop.name);
      }
    },
    getWeekLabels(array) {
      if (array) {
        return array.map((prop) => prop.week);
      }
    },
    getPropValues(object, key) {
      if (object) {
        return object.map((item) => {
          if ([key] in item) {
            return item[key];
          }
        });
      }
    },
    // New Graph Colours
    interpolateColor(color1, color2, factor) {
      if (arguments.length < 3) {
        factor = 0.5;
      }
      const result = color1.slice();
      for (let i = 0; i < 3; i++) {
        result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
      }
      return result;
    },
    interpolateColors(color1, color2, steps) {
      const stepFactor = 1 / (steps - 1);
      const interpolatedColorArray = [];
      color1 = color1.match(/\d+/g).map(Number);
      color2 = color2.match(/\d+/g).map(Number);
      for (let i = 0; i < steps; i++) {
        interpolatedColorArray.push(this.interpolateColor(color1, color2, stepFactor * i));
      }
      return interpolatedColorArray;
    },
    generateColourArray(steps) {
      steps = (steps < 2 ? 2 : steps);
      const color1 = this.hexToRgb(brandingStore.__GETTERPrimary);
      const color2 = this.hexToRgb(brandingStore.__GETTERSecondary);
      const colors = this.interpolateColors(color1, color2, steps);
      const newColors = [];
      for (let i = 0; i < steps; i++) {
        const combinedColor = colors[i][0] * 0x10000 + colors[i][1] * 0x100 + colors[i][2] * 0x1;
        newColors.push('#'+('000000' + combinedColor.toString(16)).slice(-6));
      }
      return newColors;
    },
    hexToRgb(hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      const newRGB = 'rgb('+parseInt(result[1], 16)+', '+parseInt(result[2], 16)+', '+parseInt(result[3], 16)+')';
      return result ? newRGB : null;
    },
  },
};
