import {format, parseISO, subDays} from 'date-fns';

export default {
  methods: {
    parseDate(date) {
      return typeof date === 'string' ? parseISO(date) : date;
    },
    formatDateYMD(date) {
      if (!date ) {
        date = new Date();
      }
      return format(this.parseDate(date), 'yyyy-MM-dd');
    },
    getLastWeek() {
      return this.formatDateYMD(subDays(new Date(), 6));
    },
    getPreviousDay(date) {
      return this.formatDateYMD(subDays(this.parseDate(date), 1));
    },
    createDates(start, end) {
      const dates = {};
      if (start) {
        dates.fromDate = this.formatDateYMD(start);
      }
      if (end) {
        dates.toDate = this.formatDateYMD(end);
      }
      return dates;
    },
  },
};
